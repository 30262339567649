<template>
    <div class="language" @mouseenter="isLanguage = true" @mouseleave="isLanguage = false">
        <div class="language_txt" @click="checklanguage" >
              <!-- <img class="language_icon" src="@/assets/images/language_icon.png" alt=""> -->
            <svg-icon class="language_icon"  icon-class="Language"></svg-icon>
              <span v-if="!mobileFlag">
                  {{$t('languageTxt')}}
              </span>

        </div>
        <ul v-if="isLanguage">
            <li :class="lang == 'zh'?'active':''" @click="changeLang('zh')">中文简体</li>
            <li :class="lang == 'en'?'active':''" @click="changeLang('en')">English</li>
        </ul>
      
    </div>
  </template>
  
  <script>
  // @ is an alias to /src
  import {mapState} from 'vuex'
  
  export default {
      name: 'language',
      computed: {
          ...mapState(['lang','mobileFlag'])
      },
      data(){
          return{
              isLanguage:false
          }
          
      },
      methods:{
          changeLang(value){
            //   setup(value)
              location.reload()
          },
          checklanguage(){
              this.isLanguage = !this.isLanguage
          }
      }
  }
  </script>
  
  <style lang="less" scoped>
  .language{
      width: 120px;
      height: 100%;
    //   position: relative;
      .language_txt{
          line-height: 40px;
          display: flex;
          flex-direction: row;
          justify-content: space-around;
          align-items: center;
          cursor: pointer;
          height: 100%;
          .language_icon{
              width:25px;
              height: 25px;
              color: #212121;
          }
          span{
            font-size: 18px;
          }
      }
      ul{
          position: absolute;
          width: 140px;
          background: #fff;
          box-shadow: 0 0 20px 0  #8d8b8b;
          padding:  0;
          top: 50px;
          left: 0px;
          z-index: 99;
          li{
              list-style-type:none;
              text-align: center;
              border-radius: 4px;
              color: var(--black_font);
              font-size: 18px;
              line-height: 34px;
              padding: 10px 0;
              cursor: pointer;
          }
      }
  
  }
.language:hover{
    ul{
        display: block;
    }
}


@media screen and (max-width: 767px) {
    .language{
        width: auto;
        margin-right: 25px;
        .language_txt{
            .language_icon{
                color: #fff;
            }
        }
        ul{
            left: 60%;
        }
    }
}
  </style>
  
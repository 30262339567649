<template>
  <div class="menu">
    <el-menu @select="handleSelect" class="menuList" :default-active="$route.path" :router="true">
      <el-menu-item index="/HomeIndex" class="menuNoChild" v-if="isFollower || isSignal">
        <div class="mobileMenuItem">
          <svg-icon icon-class="MenuHome"></svg-icon>
          <span slot="title">{{ $t("menu_home") }}</span>
        </div>
      </el-menu-item>
      <el-submenu index="1">
        <template slot="title">
          <svg-icon icon-class="FwMenuIcon"></svg-icon>
          <span slot="title">{{ $t("MyFollowers_Myfollowers") }}</span>
        </template>
        <el-menu-item-group>
          <el-menu-item index="/FollowInvited">{{ $t("MyFollowers_BeingInvited") }}
            <span class="invitedNum" v-if="followData.invited !== 0">
              {{ followData.invited }}
            </span>
          </el-menu-item>
          <el-menu-item index="/MyFollow">{{ $t("MyFollowers_Myfollowers_list") }}</el-menu-item>
          <el-menu-item index="/FollowHistory">{{ $t("historicalProfit") }}</el-menu-item>
        </el-menu-item-group>
      </el-submenu>
      <!-- <el-menu-item index="/MyFollow">
        <svg-icon icon-class="FwMenuIcon"></svg-icon>
        <span slot="title">{{ $t("MyFollowers_Myfollowers") }}</span>
      </el-menu-item> -->
      <el-submenu index="2">
        <template slot="title">
          <svg-icon icon-class="FwWifiIcon"></svg-icon>
          <span slot="title">{{ $t("menu_symbolAll") }}</span>
        </template>
        <el-menu-item-group>
          <el-menu-item index="/MySignal">{{ $t("MyFollowers_MySignalSource") }}</el-menu-item>
          <el-menu-item index="/FollowSymbol">{{ $t("followSymbol_title") }}</el-menu-item>
          <el-menu-item index="/historicalProfit">{{ $t("historicalProfit") }}</el-menu-item>
        </el-menu-item-group>
        <!-- <svg-icon icon-class="FwWifiIcon"></svg-icon>
        <span slot="title">{{ $t("MyFollowers_MySignalSource") }}</span> -->
      </el-submenu>
      <el-menu-item index="/MyRecord" class="menuNoChild">
        <div class="mobileMenuItem">
          <svg-icon icon-class="MenuHistory"></svg-icon>
          <span slot="title">{{ $t("MyFollowers_OperationRecord") }}</span>
        </div>
      </el-menu-item>
      <el-menu-item v-if="mobileFlag" index="/HelpCenter" class="menuNoChild">
        <div class="mobileMenuItem">
          <svg-icon icon-class="MenuMessage"></svg-icon>
          <span slot="title">{{ $t("title_help") }}</span>
        </div>
      </el-menu-item>
      <el-menu-item v-if="mobileFlag" index="/MyMessage" class="menuNoChild">
        <div class="mobileMenuItem">
          <svg-icon icon-class="MessageIcon"></svg-icon>
          <span slot="title">{{ $t("title_message") }}</span>
        </div>
      </el-menu-item>
      <!-- <el-menu-item index="/Disclaimers" >
        <svg-icon icon-class="FwRecordIcon"></svg-icon>
        <span slot="title">{{ $t("DisclaimersTitle") }}</span>
      </el-menu-item> -->
    </el-menu>

    <div class="FollowLogo" >
      <!-- <span>
        Provided by
      </span>
      <img class="logoImg" src="@/assets/images/FollowLogo.svg" alt="" />
      <p></p> -->
      <div class="DisclaimersDiv" @click="disclaimersClick()">
        {{ $t('DisclaimersTitle') }}
      </div>
    </div>


    <div v-if="false" class="mobileMenuWrap">
      <div class="mobileMenu">
        <p @click="clickMenu()">
          <!-- <svg-icon :icon-class="mobileIconChoose"></svg-icon> -->
          <span>{{ $t(mobileTxtChoose) }}</span>
          <span v-if="chooseSignal !== ''" class="signalTxt">
            {{ "— " + checkPortfolio.portfolioName }}
          </span>
          <i :class="[isClick ? 'down' : 'up']" class="el-icon-arrow-down"></i>
        </p>
        <ul class="mobileMenuUl" v-if="isClick">
          <li v-for="(item, index) in menuList" :key="index"
            @click="goPath(item.path, item.icon, item.name, item.children)"
            :class="mobilePathChoose == item.path ? 'chooseMenuMobile' : ''">
            <!-- <svg-icon :icon-class="item.icon"></svg-icon> -->
            <span>
              {{ $t(item.name) }}
              <i v-if="item.children" :class="[ischildIconClick ? 'down' : 'up']" class="el-icon-arrow-down"></i>
            </span>
            <ol :class="[isChildClick && item.children ? 'show' : '', 'menuChildOl']">
              <li v-for="(subitem, subindex) in item.children"
                @click="goPath(subitem.path, subitem.icon, subitem.name, 'childClick')"
                :class="mobilePathChoose == subitem.path ? 'chooseMenuMobile' : ''" :key="subindex">
                <b>{{ $t(subitem.name) }}</b>
              </li>
            </ol>
          </li>


        </ul>
      </div>
      <!-- <Language /> -->

    </div>
  </div>
</template>

<script>
// @ is an alias to /src
import { mapState } from "vuex";
import Language from "@/components/common/Language.vue";
import Bus from '../../plugins/bus'

export default {
  name: "Menu",
  components: {
    Language,
  },
  data() {
    return {
      mobileIconChoose: "FwMenuIcon",
      mobileTxtChoose: '',
      mobilePathChoose: '/MyFollow',
      isClick: false,
      isChildClick: false,
      ischildIconClick: false,
      isFollower:false,
      isSignal:false,
      followData:{}
    };
  },
  computed: {
    ...mapState(["mobileFlag", "checkPortfolio","chooseSignal"]),
    menuList() {
      return [
        {
          path: "",
          icon: "FwMenuIcon",
          name: "MyFollowers_Myfollowers",
          children: [
            {
              path: "/MyFollow",
              icon: "FwMenuIcon",
              name: "MyFollowers_Myfollowers_list",
            },
            {
              path: "/FollowHistory",
              icon: "FwMenuIcon",
              name: "MyFollowers_Myfollowers_history",
            },
          ]
        },
        {
          path: "/MySignal",
          icon: "FwWifiIcon",
          name: "MyFollowers_MySignalSource",
        },
        {
          path: "/MyRecord",
          icon: "FwRecordIcon",
          name: "MyFollowers_OperationRecord",
        },
        {
          path: "/Disclaimers",
          icon: "FwRecordIcon",
          name: "DisclaimersTitle",
        },
      ]
    }
  },
  mounted() {
    this.getUserType()
    this.getSymbolInfo()
    if (this.mobileFlag) {
      // console.log(this.$route.path)
      if (this.$route.path !== '/MyAccount') {
          this.$store.commit('setChooseSignal', '')
        }
      // if(this.$route.path)
      this.menuList.forEach((item, index) => {
        if (item.path == this.$route.path) {
          this.mobileIconChoose = item.icon;
          this.mobileTxtChoose = item.name;
          this.mobilePathChoose = item.path;

        } else if (item.children){

            item.children.forEach((subitem, indexs) => {
              if (subitem.path == this.$route.path) {
                this.mobileIconChoose = subitem.icon;
                this.mobileTxtChoose = subitem.name;
                this.mobilePathChoose = subitem.path;
              }
            })

        }else if(this.$route.path == '/MyAccount'){
          this.mobileTxtChoose = "MyFollowers_MySignalSource";
        }

      })
    }
    Bus.$on('isInviteRefresh', myMsg => {
        if(myMsg){
          this.getSymbolInfo()
        }
    });
  },
  methods: {
    getUserType(){
        this.$axios
        .get("/Api/home/usertype")
        .then((res) => {
          let dt = res.data;
          if (dt.isSuccess) {
            this.isFollower = dt.result.isFollower
            this.isSignal = dt.result.isSignal
            // if(!this.isFollower && !this.isSignal){
            //   this.$router.push({ path: '/MyFollow' });
            // }

          } else {
            this.$notify.error({title: this.$t('MyFollowers_prompt'), message:dt.result});
          }
        })
      },
    clickMenu() {
      this.isClick = !this.isClick;
    },
    goPath(path, icon, txt, child) {

      if (path) {
        this.$router.push({ path: path });
        this.mobileIconChoose = icon;
        this.mobileTxtChoose = txt;
        this.mobilePathChoose = path
        this.isClick = false
        this.isChildClick = false
        this.ischildIconClick = false
        if (path !== '/MyAccount') {
          this.$store.commit('setChooseSignal', '')
        }
      } else {
        if (child && child !== 'childClick') {

          this.isChildClick = !this.isChildClick
          this.ischildIconClick = !this.ischildIconClick
        } else {
          this.isClick = false
        }
      }

    },
    getSymbolInfo(){
        this.$axios
        .get("/Api/home/index")
        .then((res) => {
          let dt = res.data;
          if (dt.isSuccess) {
            this.followData = dt.result.followData

          } else {
            this.$notify.error({title: this.$t('MyFollowers_prompt'), message:dt.result});
          }
        })
      },
    disclaimersClick() {
      this.$router.push({ path: '/Disclaimers' });
      Bus.$emit('isMobileMenu', false);
    },
    handleSelect(){
      Bus.$emit('isMobileMenu', false);
    }
  },
};
</script>
<style lang="less" scoped>
.menu {
  display: flex;
  flex-direction: column;
  width: 264px;
  height: 100%;
  background: #2F3554;
  position: relative;

  .FollowLogo {
    width: 125px;
    bottom: 40px;
    left: 50%;
    transform: translate(-50%, 0);
    position: absolute;

    span {
      display: inline-block;
      color: #959595;
      font-size: 12px;
      margin-bottom: 10px;
    }

    p {
      height: 1px;
      background: #3A3A3A;
      padding: 0;
      margin: 20px 0;
      width: 150%;
      margin-left: -25%;
    }

    .DisclaimersDiv {
      color: #FFFFFF;
      font-size: 14px;
      font-family: 'PingFangSC-Regular';
      cursor: pointer;
      text-align: center;
      opacity: 0.5;
    }
  }

  .menuList {
    margin: 35px 0;
    min-width: 100%;

    .svg-icon {
      color: #FFFFFF;
      width: 22px;
      height: 22px;
    }

    .is-active {
      .svg-icon {
        color: #ffffff;
      }
    }

    // .iconNone{
    //   display: block;

    // }
    // .iconActive{
    //   display: none;
    // }
  }
  .invitedNum{
    border-radius: 17px;
    background: #4D71FF;
    color: #FFF;
    font-family: 'PingFangSC-Medium';
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    padding: 2px 6px;
  }
}

@media screen and (max-width:1445px){
    .menu{
        width: 285px;
      .logoImg{
        margin-left: -20px;
      }
    }
}

@media screen and (max-width:1289px){
  .menu{
        width: 300px;
    }
}
@media screen and (max-width:1025px) {

  .menu {
    width: 20%;
    .FollowLogo{
      span{
        white-space: nowrap;
      }
      .logoImg{
        margin-left: -40px;
      }
    }

  }

}
@media screen and (max-width:821px) {

  .menu {
    width: 20%;
    .FollowLogo{
      span{
        margin-left: -20px;
      }
      .logoImg{
        width: 200px;
        margin-left: -20px;
      }
      .DisclaimersDiv{
        margin-left: -20px;
      }
    }

  }

}

@media screen and (max-width: 767px) {
  // .menu {

  //   .FollowLogo {
  //     width: 80px;
  //     height: 26px;
  //     margin: 10px 30px;
  //   }

  //   .mobileMenuWrap {
  //     display: none;
  //   }

  //   .mobileMenu {
  //     height: 100%;
  //     position: relative;
  //     z-index: 999;

  //     p {
  //       margin: 12px 0 12px 12px;
  //       display: flex;
  //       flex-direction: row;
  //       align-items: center;

  //       .svg-icon {
  //         width: 18px;
  //         height: 18px;
  //         color: #212121;
  //       }

  //       span {
  //         font-family: "PingFangSC-Medium";
  //         font-size: 18px;
  //         // line-height: 21px;
  //         /* identical to box height */
  //         color: #212121;
  //         margin-left: 8px;
  //         font-weight: 600;
  //       }
  //       .signalTxt{
  //         width: 120px;
  //         overflow: hidden;
  //         white-space: nowrap;
  //         text-overflow: ellipsis;
  //       }

  //       i {
  //         color: #212121;
  //         font-size: 20px;
  //         margin-left: 10px;
  //       }
  //     }

  //     .mobileMenuUl {
  //       margin: 0;
  //       padding: 0;
  //       list-style: none;
  //       background: #2f2f2f;
  //       border-radius: 8px;
  //       width: 150px;
  //       position: absolute;
  //       left: 20px;
  //       top: 50px;

  //       li {
  //         min-height: 48px;
  //         line-height: 48px;
  //         display: flex;
  //         flex-direction: column;
  //         // align-items: center;
  //         // text-align: center;
  //         justify-content: center;
  //         padding-left: 40px;
  //         border-bottom: 1px solid #232323;

  //         .svg-icon {
  //           color: #ffffff;
  //           width: 18px;
  //           height: 18px;
  //           margin: 0 10px 0 22px;
  //         }

  //         span {
  //           font-family: "PingFangSC-Regular";

  //           font-size: 14px;
  //           line-height: 49px;
  //           /* identical to box height */
  //           color: #ffffff;
  //           position: relative;

  //           i {
  //             position: absolute;
  //             right: 15px;
  //             top: 40%;
  //           }
  //         }
  //       }

  //       .chooseMenuMobile>span {


  //           color: #4D71FF !important;


  //         // .svg-icon {
  //         //   color: #4D71FF;
  //         // }
  //       }

  //       .menuChildOl {
  //         height: 0;
  //         width: 100%;
  //         padding: 0;
  //         overflow: hidden;
  //         transition: all 1s;

  //         li {
  //           padding: 0;
  //           border-bottom: 0;

  //           b {
  //             color: #FFFFFF;
  //             margin-left: 20px;
  //             font-weight: 500;
  //             font-family: "PingFangSC-Regular";

  //             font-size: 14px;
  //             line-height: 49px;
  //           }
  //         }
  //       }
  //       .chooseMenuMobile>b{
  //         color: #4D71FF !important;
  //       }
  //       .show {
  //         height: 100px;

  //       }

  //     }

  //     .up {
  //       transition: all 1s;
  //     }

  //     .down {
  //       transform: rotate(-180deg);
  //       transition: all 1s;
  //     }
  //   }

  // }

  .enHtml {
    .menu {
      .FollowLogo {
        margin-right: 15px;
      }

      .mobileMenu {
        p {
          margin-right: 15px;

          span {
            font-size: 18px;
          }
        }

        .mobileMenuUl {
          width: 200px;
        }
      }
    }
  }
  .Title{
    .menu {
      position: absolute;
      width: 100%;
      top: 45px;
      height: auto;
      .menuList{
        margin: 0;
      }
      .FollowLogo{
        bottom: 15px;
      }
    }
  }
}</style>
